import {XHTTP} from '@/shared/xhttp';
import {LogementService} from "@/xhttp/LogementService";
import {DateHelper} from "@/shared/utils";
import {Proprietaire} from "@/models/Proprietaire";

@XHTTP(LogementService, '/logement')
export class Logement {

	private _id: number;
	private _name: string;
	private _proprietaire: Proprietaire;
	private _address: string;
	private _zipCode: string;
	private _town: string;
	private _availability: boolean;
	private _createdAt: Date;

	/////////////
	// Getters //
	/////////////

	public get id(): number {
		return this._id;
	}

	public get name(): string {
		return this._name;
	}

	public get proprietaire(): Proprietaire {
		return this._proprietaire;
	}

	public get address(): string {
		return this._address;
	}

	public get zipCode(): string {
		return this._zipCode;
	}

	public get town(): string {
		return this._town;
	}

	public get fullAddress(): string {
		return (this.address.trim()+' '+this.zipCode.trim()+ ' '+this.town.trim()).trim();
	}

	public get availability(): boolean {
		return this._availability;
	}

	public get createdAt(): Date {
		return this._createdAt;
	}

	/////////
	// Add //
	/////////
	
	/////////////
	// Setters //
	/////////////

	public set name(value: string) {
		this._name = value;
	}
	
	public set proprietaire(value: Proprietaire) {
		this._proprietaire = value;
	}

	public set address(value: string) {
		this._address = value;
	}

	public set zipCode(value: string) {
		this._zipCode = value;
	}

	public set town(value: string) {
		this._town = value;
	}

	public set availability(value: boolean) {
		this._availability = value;
	}

	public set createdAt(value: Date) {
		this._createdAt = value;
	}

	///////////////
	// Serialize //
	///////////////

	public toJSON(): any {
		return {
			id            : this._id,
			name: this._name,
			proprietaire  : this._proprietaire ? this._proprietaire.id : null,
			address: this._address,
			zipCode: this._zipCode,
			town   : this._town,
			availability   : this._availability,
		};
	}

	public fromJSON(data: any): this {
		this._id           = data.id
		this._name   = data.name;
		this._proprietaire    = data.proprietaire ? (new Proprietaire()).fromJSON(data.proprietaire) : null;
		this._address   = data.address;
		this._zipCode   = data.zipCode;
		this._town      = data.town;
		this._availability    = data.availability;
		this._createdAt = data.createdAt ? new Date(data.createdAt) : null;
		return this;
	}
}
