import {XHTTP} from '@/shared/xhttp';
import {LocataireService} from "@/xhttp/LocataireService";
import {DateHelper} from "@/shared/utils";
import {Logement} from "@/models/Logement";
import {Tva} from "@/models/Tva";
import {AvisLoyer} from "@/models/AvisLoyer";

export type TvaType = 'HT'|'TTC';
export const TvaType = {
	HT   : 'HT' as TvaType,
	TTC	: 'TTC' as TvaType,
};

export type PeriodicityType = 'Mensuel'|'Trimestriel';
export const PeriodicityType = {
	MENSUEL   : 'Mensuel' as PeriodicityType,
	TRIMESTRIEL	: 'Trimestriel' as PeriodicityType,
};

export type TermType = 'Echu'|'A echoir';
export const TermType = {
	ECHU   : 'Echu' as TermType,
	AECHOIR	: 'A echoir' as TermType,
};

@XHTTP(LocataireService, '/locataire')
export class Locataire {

	private _id: number;
	private _number: number;
	private _date: Date;
	private _firstName: string;
	private _lastName: string;
	private _logement: Logement;
	private _duration: number = 0;
	private _deposit: number = 0;
	private _amountRental: number = 0;
	private _amountAdvances: number = 0;
	private _amountAnnualAdjustment: number = 0;
	private _tvaType: TvaType = TvaType.HT;
	private _periodicityType: PeriodicityType = PeriodicityType.MENSUEL;
	private _termType: TermType = TermType.ECHU;
	private _tva: Tva;
	private _transferCaf: number = 0;
	private _address: string;
	private _zipCode: string;
	private _town: string;
	private _toPaid: number = 0.0;
	private _state: boolean;
	private _avisLoyers: AvisLoyer[] = [];
	private _createdAt: Date;

	/////////////
	// Getters //
	/////////////

	public get id(): number {
		return this._id;
	}

	public get number(): number {
		return this._number;
	}

	public get date(): Date {
		return this._date;
	}

	public get firstName(): string {
		return this._firstName;
	}

	public get lastName(): string {
		return this._lastName;
	}

	public get fullName(): string {
		return (this.firstName.trim()+' '+this.lastName.trim()).trim();
	}

	public get logement(): Logement {
		return this._logement;
	}

	public get duration(): number {
		return this._duration;
	}

	public get deposit(): number {
		return this._deposit;
	}

	public get amountRental(): number {
		return this._amountRental;
	}

	public get amountAdvances(): number {
		return this._amountAdvances;
	}

	public get amountAnnualAdjustment(): number {
		return this._amountAnnualAdjustment;
	}

	public get tvaType(): TvaType {
		return this._tvaType ? <any>this._tvaType.toString() : null;
	}

	public get periodicityType(): PeriodicityType {
		return this._periodicityType ? <any>this._periodicityType.toString() : null;
	}

	public get termType(): TermType {
		return this._termType ? <any>this._termType.toString() : null;
	}

	public get tva(): Tva {
		return this._tva;
	}

	public get transferCaf(): number {
		return this._transferCaf;
	}

	public get address(): string {
		return this._address;
	}

	public get zipCode(): string {
		return this._zipCode;
	}

	public get town(): string {
		return this._town;
	}

	public get fullAddress(): string {
		return (this.address.trim()+' '+this.zipCode.trim()+ ' '+this.town.trim()).trim();
	}

	public get toPaid(): number {
		return this._toPaid;
	}

	public get state(): boolean {
		return this._state;
	}

	public get avisLoyers(): AvisLoyer[] {
		return this._avisLoyers;
	}

	public get createdAt(): Date {
		return this._createdAt;
	}

	/////////
	// Add //
	/////////
	
	/////////////
	// Setters //
	/////////////

	public set number(value: number) {
		this._number = value;
	}

	public set date(value: Date) {
		this._date = value;
	}

	public set firstName(value: string) {
		this._firstName = value;
	}

	public set lastName(value: string) {
		this._lastName = value;
	}
	
	public set logement(value: Logement) {
		this._logement = value;
	}

	public set duration(value: number) {
		this._duration = value;
	}

	public set deposit(value: number) {
		this._deposit = value;
	}

	public set amountRental(value: number) {
		this._amountRental = value;
	}

	public set amountAdvances(value: number) {
		this._amountAdvances = value;
	}

	public set amountAnnualAdjustment(value: number) {
		this._amountAnnualAdjustment = value;
	}

	public set tvaType(value: TvaType) {
		this._tvaType = value;
	}

	public set periodicityType(value: PeriodicityType) {
		this._periodicityType = value;
	}

	public set termType(value: TermType) {
		this._termType = value;
	}

	public set tva(value: Tva) {
		this._tva = value;
	}

	public set transferCaf(value: number) {
		this._transferCaf = value;
	}

	public set address(value: string) {
		this._address = value;
	}

	public set zipCode(value: string) {
		this._zipCode = value;
	}

	public set town(value: string) {
		this._town = value;
	}

	public set toPaid(value: number) {
		this._toPaid = value;
	}

	public set state(value: boolean) {
		this._state = value;
	}

	public set avisLoyers(value: AvisLoyer[]) {
		this._avisLoyers = value;
	}

	public set createdAt(value: Date) {
		this._createdAt = value;
	}

	///////////////
	// Serialize //
	///////////////

	public toJSON(): any {
		return {
			id            : this._id,
			number: this._number,
			date    : this._date ? DateHelper.toLocalizedString(this._date) : null,
			firstName: this._firstName,
			lastName : this._lastName,
			logement  : this._logement ? this._logement.id : null,
			duration : this._duration,
			deposit : this._deposit,
			amountRental : this._amountRental,
			amountAdvances : this._amountAdvances,
			amountAnnualAdjustment : this._amountAnnualAdjustment,
			tvaType : this._tvaType ? this._tvaType.toString() : null,
			periodicityType : this._periodicityType ? this._periodicityType.toString() : null,
			termType : this._termType ? this._termType.toString() : null,
			tva  : this._tva ? this._tva.id : null,
			transferCaf : this._transferCaf,
			address: this._address,
			zipCode: this._zipCode,
			town   : this._town,
			toPaid   : this._toPaid,
			state   : this._state,
			avisLoyer: this._avisLoyers ? this._avisLoyers.map(r => r.toJSON()) : [],
		};
	}

	public fromJSON(data: any): this {
		this._id           = data.id
		this._number   = data.number;
		this._date   = data.date ? new Date(data.date) : null;
		this._firstName = data.firstName;
		this._lastName  = data.lastName;
		this._logement    = data.logement ? (new Logement()).fromJSON(data.logement) : null;
		this._duration  = data.duration;
		this._deposit  = data.deposit;
		this._amountRental  = data.amountRental;
		this._amountAdvances  = data.amountAdvances;
		this._amountAnnualAdjustment  = data.amountAnnualAdjustment;
		this._tvaType  = data.tvaType;
		this._periodicityType  = data.periodicityType;
		this._termType  = data.termType;
		this._tva    = data.tva ? (new Tva()).fromJSON(data.tva) : null;
		this._transferCaf  = data.transferCaf;
		this._address   = data.address;
		this._zipCode   = data.zipCode;
		this._town      = data.town;
		this._toPaid      = data.toPaid;
		this._state    = data.state;
		this._avisLoyers = data.avisLoyers ? data.avisLoyers.map(r => (new AvisLoyer()).fromJSON(r)) : [];
		this._createdAt = data.createdAt ? new Date(data.createdAt) : null;
		return this;
	}
}
