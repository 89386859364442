import Vue from 'vue';
import Vuex, {Store} from 'vuex';
import {AscDesc, ResultType, XHTTPService} from '@/shared/xhttp';
import {NumberFilter} from '@/shared/filter';
import {DateFilter} from '@/shared/filter';
import {
	PaginatorActionWithFilters,
	PaginatorFilterState,
	PaginatorMutationFilter,
	StandardAction
} from '@/shared/utils';
import {Logement} from '@/models';
import {LogementService} from '@/xhttp';
import store from '@/stores';


Vue.use(Vuex);

export class LogementFilter {

	public name: string = '';
	public proprietaire: string = '';
	public address: string = '';
	public zipCode: string = '';
	public town: string = '';
	public availability: boolean = false;

	public toString(): string {
		return JSON.stringify({
			name  : this.name,
			proprietaire  : this.proprietaire,
			address  : this.address,
			zipCode  : this.zipCode,
			town  : this.town,
			availability  : this.availability,
		});
	}
}

class LogementState extends PaginatorFilterState<LogementFilter> {
	public logement: Logement = null;
	public logements: ResultType<Logement> = null;
	public constructor() {
		super(new LogementFilter(), 'logements', 'logement', 'id', AscDesc.DESC);
	}
}

class LogementStore {

	@XHTTPService(() => Logement)
	private logementService: LogementService;
	private  _store: Store<any>;

	public state: LogementState = new LogementState();

	public mutations = {
		...PaginatorMutationFilter(() => this._store, 'logements', 'logement'),
	};

	public actions = {
		...PaginatorActionWithFilters<Logement, LogementState>(() => Logement, 'logements', 'logement'),
		...StandardAction<Logement, LogementState>(() => Logement, 'logement'),

	};

	public init(store: Store<any>): this {
		this._store = store;
		return this;
	}
}

const instance = new LogementStore();
const storeLogement = new Vuex.Store(instance);
instance.init(storeLogement);

const instanceAvailable = new LogementStore();
export const storeLogementAvailable = new Vuex.Store(instanceAvailable);
instanceAvailable.init(storeLogementAvailable);

store.registerModule('logement', storeLogement);
store.registerModule('logementAvailable', storeLogementAvailable);
export default storeLogement;