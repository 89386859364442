import {XHTTP} from '@/shared/xhttp';
import {ProprietaireService} from '@/xhttp';
import {DocumentProprietaire} from "@/models/DocumentProprietaire";

@XHTTP(ProprietaireService, '/proprietaire')
export class Proprietaire {
	
	private _id: number;
	private _society: string;
	private _firstName: string;
	private _lastName: string;
	private _email: string;
	private _phone1: string;
	private _address: string;
	private _zipCode: string;
	private _town: string;
	private _documents: DocumentProprietaire[] = [];
	private _createdAt: Date;
	
	/////////////
	// Getters //
	/////////////
	
	public get id(): number {
		return this._id;
	}

	public get society(): string {
		return this._society;
	}

	public get firstName(): string {
		return this._firstName;
	}

	public get lastName(): string {
		return this._lastName;
	}

	public get fullName(): string {
		if (!this._society && this._firstName && this._lastName) {
			return (this._firstName.trim()+' '+this._lastName.trim()).trim();
		} else if (!this._society && !this._firstName && this._lastName) {
			return this._lastName;
		} else if (!this._society && this._firstName && !this._lastName) {
			return this._firstName;
		} else if (this._society && !this._firstName && !this._lastName) {
			return this._society;
		} else if (this._society && this._firstName && !this._lastName) {
			return (this._firstName.trim()+' ('+this._society.trim()+')').trim();
		} else if (this._society && this._lastName && !this._firstName) {
			return (this._lastName.trim()+ ' ('+this._society.trim()+')').trim();
		} else if (this._society && this._lastName && this._firstName) {
			return (this._firstName.trim()+' '+this._lastName.trim()+ ' ('+this._society.trim()+')').trim();
		}
	}

	public get email(): string {
		return this._email;
	}
	
	public get phone1(): string {
		return this._phone1;
	}

	public get address(): string {
		return this._address;
	}

	public get zipCode(): string {
		return this._zipCode;
	}

	public get town(): string {
		return this._town;
	}

	public get fullAddress(): string {
		if (this.address && this.zipCode && this.town) {
			return (this.address.trim()+' '+this.zipCode.trim()+' '+this.town.trim()).trim();
		} else if (this.address && this.zipCode && !this.town) {
			return (this.address.trim()+' '+this.zipCode.trim()).trim();
		} else if (this.address && !this.zipCode && this.town) {
			return (this.address.trim()+' '+this.town.trim()).trim();
		} else if (!this.address && this.zipCode && this.town) {
			return (this.zipCode.trim()+' '+this.town.trim()).trim();
		} else if (!this.address && !this.zipCode && this.town) {
			return this.town.trim();
		} else if (this.address && !this.zipCode && !this.town) {
			return this.address.trim();
		} else if (!this.address && this.zipCode && !this.town) {
			return this.zipCode.trim();
		} else {
			return '';
		}
	}
	
	public get documents(): DocumentProprietaire[] {
		return this._documents;
	}
	
	public get createdAt(): Date {
		return this._createdAt;
	}

	/////////////
	// Setters //
	/////////////

	public set society(value: string) {
		this._society = value;
	}

	public set firstName(value: string) {
		this._firstName = value;
	}

	public set lastName(value: string) {
		this._lastName = value;
	}
	
	public set email(value: string) {
		this._email = value;
	}
	
	public set phone1(value: string) {
		this._phone1 = value;
	}

	public set address(value: string) {
		this._address = value;
	}

	public set zipCode(value: string) {
		this._zipCode = value;
	}

	public set town(value: string) {
		this._town = value;
	}
	
	public set createdAt(value: Date) {
		this._createdAt = value;
	}
	
	///////////////////
	// Add & Remove //
	//////////////////

	public addDocuments(document: DocumentProprietaire): this {
		document.proprietaire = this;
		if (this._documents.indexOf(document) === -1) {
			this._documents.push(document);
		}
		return this;
	}

	public removeDocuments(document: DocumentProprietaire): this {
		document.proprietaire = this;
		const index = this._documents.indexOf(document);
		if (index !== -1) {
			this._documents.splice(index, 1);
		}
		return this;
	}
	
	///////////////
	// Serialize //
	///////////////

	public toJSON(): any {
		return {
			id       : this._id,
			society: this._society,
			firstName: this._firstName,
			lastName : this._lastName,
			email    : this._email,
			phone1   : this._phone1,
			address: this._address,
			zipCode: this._zipCode,
			town   : this._town,
		};
	}

	public fromJSON(data: any): this {
		this._id        = data.id;
		this._society = data.society;
		this._firstName = data.firstName;
		this._lastName  = data.lastName;
		this._email     = data.email;
		this._phone1    = data.phone1;
		this._address   = data.address;
		this._zipCode   = data.zipCode;
		this._town      = data.town;
		if (data.documentProprietaires)  {
			for (const d of data.documentProprietaires) {
				this.addDocuments((new DocumentProprietaire()).fromJSON(d));
			}
		}
		this._createdAt = data.createdAt ? new Date(data.createdAt) : null;
		return this;
	}
}
