import Vue from 'vue';
import Vuex, {ActionContext, Store} from 'vuex';
import {AscDesc, ResultType, XHTTPService} from '@/shared/xhttp';
import {NumberFilter} from '@/shared/filter';
import {DateFilter} from '@/shared/filter';
import {
	PaginatorActionWithFilters,
	PaginatorFilterState,
	PaginatorMutationFilter,
	StandardAction
} from '@/shared/utils';
import {Locataire} from "@/models/Locataire";
import {LocataireService} from "@/xhttp/LocataireService";
import store from '@/stores';


Vue.use(Vuex);

export class LocataireFilter {

	public name: string = '';
	public proprietaire: string = '';
	public address: string = '';
	public zipCode: string = '';
	public town: string = '';
	public state: boolean[] = [];

	public toString(): string {
		return JSON.stringify({
			name  : this.name,
			proprietaire  : this.proprietaire,
			address  : this.address,
			zipCode  : this.zipCode,
			town  : this.town,
			state  : this.state,
		});
	}
}

class LocataireState extends PaginatorFilterState<LocataireFilter> {
	locataire: Locataire = null;
	locataires: ResultType<Locataire> = null;
	public constructor() {
		super(new LocataireFilter(), 'locataires', 'locataire', 'id', AscDesc.DESC);
	}
}

class LocataireStore {

	@XHTTPService(() => Locataire)
	private locataireService: LocataireService;
	private  _store: Store<any>;

	public state: LocataireState = new LocataireState();

	public mutations = {
		...PaginatorMutationFilter(() => this._store, 'locataires', 'locataire'),
	};

	public actions = {
		...PaginatorActionWithFilters<Locataire, LocataireState>(() => Locataire, 'locataires', 'locataire'),
		...StandardAction<Locataire, LocataireState>(() => Locataire, 'locataire'),

		putMarkState(context: ActionContext<LocataireState, any>, locataire: Locataire) {
			return instance.locataireService.putMarkState(locataire);
		},

		createAvisLoyer(context: ActionContext<LocataireState, any>, array: Array<any>) {
			return instance.locataireService.createAvisLoyer(array);
		},
	};


	public init(store: Store<any>): this {
		this._store = store;
		return this;
	}
}

const instance = new LocataireStore();
const storeLocataire = new Vuex.Store(instance);
instance.init(storeLocataire);

const instanceAvailable = new LocataireStore();
export const storeLocataireArchive = new Vuex.Store(instanceAvailable);
instanceAvailable.init(storeLocataireArchive);

store.registerModule('locataire', storeLocataire);
store.registerModule('locataireArchive', storeLocataireArchive);
export default storeLocataire;