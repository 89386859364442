







































































































































































































































import {Component} from '@/shared/component';
import {AbstractEditVue} from '@/components/Abstract/AbstractEditVue';
import {Locataire, Tva} from '@/models';
import {PeriodicityType} from '@/models';
import storeLocataire, {LocataireFilter} from '@/stores/modules/locataire';
import storeLogement from '@/stores/modules/logement';

import InputPhone from '@/components/Input/Phone.vue';
import InputZipCode from "@/components/Input/ZipCode.vue";
import InputDatePicker from "@/components/Input/DatePicker.vue";
import CrudLogementListSelect from "@/components/Crud/Logement/ListSelect.vue";
import {Emit} from "vue-property-decorator";
import store from "@/stores";
import storeTva from "@/stores/modules/tva";
import {Stored} from "vue-stored-prop-decorator";

@Component({
	components: {
		InputPhone,
		InputZipCode,
		InputDatePicker,
		CrudLogementListSelect,
	}
})
export default class CrudLocataireEdit extends AbstractEditVue<Locataire> {

	private storeLogement = storeLogement;
	private storeLocataire = storeLocataire;
	private storeTva = storeTva;
	private step: number = 0;
	private PeriodicityType = PeriodicityType
	private nextDisabled: boolean = true
	private nextDisabledTva: boolean = true
	@Stored(() => storeLocataire) private filters!: LocataireFilter;


	public constructor() {
		super(
			storeLocataire,
			'Locataire modifié',
			'Locataire ajouté',
		);
	}

	public async mounted(): Promise<void> {
		await super.mounted();
		this.filters.state = [true];
		await storeTva.dispatch('getC');
		this.item.tvaType = 'TTC';
		this.item.tva = new Tva();
		this.item.tva.id = 3;
	}

	@Emit()
	private onNext() {
		if (this.item.logement) {
			this.item.address = this.item.logement.address
			this.item.zipCode = this.item.logement.zipCode
			this.item.town = this.item.logement.town
			this.step++
		}
	}

	@Emit()
	private onPrev() {
		this.step--
	}

	private watcher() {
		if (this.item.firstName && this.item.lastName) {
			this.nextDisabled = false;
		} else {
			this.nextDisabled = true
		}
		if (this.item.tva) {
			this.nextDisabledTva = false;
		} else {
			this.nextDisabledTva = true;
		}

	}

	protected async onSubmit(): Promise<void> {
		await super.onSubmit();
		this.step = 1;
	}

	private labelAdjustment() {
		if (this.item.tvaType === 'HT') {
			return 'Montant des régularisations annuelle HT'
		} else {
			return 'Montant des régularisations annuelle'
		}
	}

	private labelAdvances() {
		if (this.item.tvaType === 'HT') {
			return 'Montant des avances/charges HT'
		} else {
			return 'Montant des avances/charges'
		}
	}

	private labelRental() {
		if (this.item.tvaType === 'HT') {
			return 'Montant du loyer HT'
		} else {
			return 'Montant du loyer'
		}
	}

}
