







































	import {Emit} from "vue-property-decorator";
	import {InOut} from 'vue-inout-prop-decorator';
	import {Component, Vue} from '@/shared/component';

	import {Logement} from '@/models';

	import CrudLogementEdit from '@/components/Crud/Logement/Edit.vue';
	import storeLogement from "@/stores/modules/logement";

	@Component({
		components: {
			CrudLogementEdit,
		}
	})
	export default class CrudLogementEditDialog extends Vue {
		@InOut({ type: Logement  , isVModel: true }) private value!: Logement;

		@Emit()
		private onSubmit(logement: Logement) {
			this.onClose();
		}

		@Emit()
		private async onClose(): Promise<void> {
			if (this.value.id)
			{
				if (
					this.$route.name !== 'list_logements'
				) {
					await this.$router.push({name: 'list_logements'});
				} else {
					await storeLogement.dispatch('getC');
				}
			}
			this.value = null;
		}

}
