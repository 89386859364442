
















































import {Emit} from "vue-property-decorator";
import {Component, Vue} from '@/shared/component';
import {InOut} from "vue-inout-prop-decorator/index";
import {Stored} from 'vue-stored-prop-decorator';
import {Form} from '@/shared/form';
import {TokenLogin} from '@/models';

import storeToken from '@/stores/modules/token';
import storeLoader from '@/stores/modules/loader';
import storeNotifier, {NotifyInterface} from '@/stores/modules/notifier';

@Component()
export default class LoginForm extends Vue {

	@Stored(() => storeLoader)
	private loading!: boolean;

	@InOut({ type: TokenLogin, isVModel:true })
	private value: TokenLogin;
	
	private form: Form = new Form();

	private showPassword: boolean = false;

	@Emit()
	public displayResetPassword(): void {
	}
	
	public async login(): Promise<void> {
		this.form.call(async () => {
			await storeToken.dispatch('login', this.value);
			this.$router.push({name: 'home'});

			storeNotifier.dispatch('notify', {
				type: 'success',
				message: 'Connexion réussie'
			} as NotifyInterface);
		});
	}
}

