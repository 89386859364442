







































import {Component, Vue} from '@/shared/component';
import {Stored} from 'vue-stored-prop-decorator';
import {Token, TokenLogin} from '@/models';

import storeToken from '@/stores/modules/token';
import storeLoader from '@/stores/modules/loader';

import LoginForm from '@/components/Login/LoginForm.vue';
import ResetPasswordRequestForm from '@/components/Login/ResetPasswordRequestForm.vue';
import {Prop} from "vue-property-decorator";

@Component({
	components: {
		LoginForm,
		ResetPasswordRequestForm,
	}
})
export default class ViewLogin extends Vue {
	
	@Stored(() => storeLoader)
	private loading!: boolean;
	
	@Stored(() => storeToken)
	private token!: Token;

	@Prop({ type: String, default: ''}) bg_rdm;
	
	private tokenLogin: TokenLogin = new TokenLogin();
	private displayResetPassword = false;

	/* public bg_random(): void {
		const number = Math.floor(Math.random() * 10);
		this.bg_rdm = 'bg-' + number;
		console.log('return', this.bg_rdm);
	} */

	public mounted(): void {
		this.noContainer();
		if (this.token) {
			this.$router.push({ name: 'home' })
		}
		// this.bg_random();
	}
}
