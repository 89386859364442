import {Logement} from "@/models";

export default [
	{
		path: '/logement',
		name: 'list_logements',
		meta: {
			title: 'Liste des livraisons',
			granted: [ 'API_GET_LOGEMENT' ]
		},
		component: () => import(/* webpackChunkName: "dashboard" */ '../views/Logement.vue')
	},
	{
		path: '/logement/edit/:id',
		name: 'logement-edit',
		props: {
			slotData: () => ({
				component: import(/* webpackChunkName: "dashboard" */ '../components/Crud/Logement/Edit.vue'),
				class: Logement
			}),
		},
		meta: {
			title: 'Edition d\'une livraison',
			granted: [ 'API_PUT_LOGEMENT' ]
		},
		component: () => import(/* webpackChunkName: "dashboard" */ '../views/EditShow.vue')
	},
];
