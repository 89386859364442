

















































import {Component, Vue} from '@/shared/component';
import {Stored} from "vue-stored-prop-decorator/index";
import {User} from '@/models';

import storeUser from '@/stores/modules/user';
import storeToken from "@/stores/modules/token";

import AjaxLoader from '@/components/AjaxLoader.vue';

@Component({
	components: {
		AjaxLoader
	}
})
export default class NavigationAppBar extends Vue {
	@Stored(() => storeUser) me!: User;

	public get hasBar(): boolean {
		return !this.$route.meta.loginPage && !!this.me;
	}

	public get title(): string {
		return this.$route.meta.title ? this.$route.meta.title : 'Scilievre';
	}

	private logout() {
		storeToken.dispatch('clear');
		this.$router.push({name: 'login'})
	}
}
