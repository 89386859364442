export default [
	{
		path: '/user',
		name: 'list_user',
		meta: {
			title:'Liste des utilisateurs',
			granted: [ 'API_GET_USER' ]
		},
		component: () => import(/* webpackChunkName: "dashboard" */ '../views/User.vue')
	},
];