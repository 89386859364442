import {Locataire} from "@/models/Locataire";

export default [
	{
		path: '/locataire',
		name: 'list_locataires',
		meta: {
			title: 'Locataire en cours',
			granted: [ 'API_GET_LOCATAIRE' ]
		},
		component: () => import(/* webpackChunkName: "dashboard" */ '../views/Locataire.vue')
	},
	{
		path: '/locataire-archive',
		name: 'list_locataires_archive',
		meta: {
			title: 'Archive des locataires',
			granted: [ 'API_GET_LOCATAIRE' ]
		},
		component: () => import(/* webpackChunkName: "dashboard" */ '../views/LocataireArchive.vue')
	},
	{
		path: '/locataire/show/:id',
		name: 'locataire-show',
		props: {
			slotData: () => ({
				component: import(/* webpackChunkName: "dashboard" */ '../components/Crud/Locataire/Show.vue'),
				class: Locataire
			}),
			editRoute: { name: 'locataire-edit' },
		},
		meta: {
			title: 'Détails d\'un locataire',
			granted: [ 'API_GET_LOCATAIRE' ]
		},
		component: () => import(/* webpackChunkName: "dashboard" */ '../views/EditShow.vue')
	},
	{
		path: '/locataire/edit/:id',
		name: 'locataire-edit',
		props: {
			slotData: () => ({
				component: import(/* webpackChunkName: "dashboard" */ '../components/Crud/Locataire/Edit.vue'),
				class: Locataire
			}),
		},
		meta: {
			title: 'Edition d\'une livraison',
			granted: [ 'API_PUT_LOCATAIRE' ]
		},
		component: () => import(/* webpackChunkName: "dashboard" */ '../views/EditShow.vue')
	},
];
