import Vue from 'vue';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';
import '@/assets/scss/vuetify/index.scss';
import fr from 'vuetify/src/locale/fr';
import DeleteFDI from '@carbon/icons-vue/es/trash-can/16'
import SeeFDI from '@carbon/icons-vue/es/view/16'
import SeeCloseFDI from '@carbon/icons-vue/es/view--off/16'
import EditFDI from '@carbon/icons-vue/es/edit/16'
import PaidFDI from '@carbon/icons-vue/es/currency/16'
import DownloadFDI from '@carbon/icons-vue/es/download/16'
import DocumentFDI from '@carbon/icons-vue/es/document/16'
import ArrowUpFDI from '@carbon/icons-vue/es/chevron--up/16'
import DashboardFDI from '@carbon/icons-vue/es/workspace/24'
import TiersFDI from '@carbon/icons-vue/es/group/24'
import SettingsFDI from '@carbon/icons-vue/es/settings/24'
import FournisseursFDI from '@carbon/icons-vue/es/identification/24'
import ClientsFDI from '@carbon/icons-vue/es/identification/24'
import ContactsFDI from '@carbon/icons-vue/es/report/24'
import LogoutFDI from '@carbon/icons-vue/es/logout/16'
import NewFournisseurClientFDI from '@/components/Icons/NewFournisseurClientFDI.vue'
import AchatsFDI from '@carbon/icons-vue/es/store/24'
import AchatsChildrenFDI from '@carbon/icons-vue/es/shopping--cart/24'
import NewAchatsFDI from '@carbon/icons-vue/es/shopping--cart--plus/24'
import LivraisonsFDI from '@carbon/icons-vue/es/delivery/24'
import VentesFDI from '@carbon/icons-vue/es/finance/24'
import ListVentesFDI from '@carbon/icons-vue/es/receipt/24'
import NewVentesFDI from '@/components/Icons/NewVentes.vue'
import EssencesFDI from '@carbon/icons-vue/es/tree/24'
import LongueursFDI from '@carbon/icons-vue/es/pan--horizontal/24'
import TransportsFDI from '@carbon/icons-vue/es/delivery-truck/24'

Vue.use(Vuetify);

// noinspection TypeScriptValidateTypes
export default new Vuetify({
    lang: {
      locales: { fr },
      current: 'fr',
    },
	icons: {
		iconfont: 'mdi',
		values: {
			delete: { component: DeleteFDI },
			see: { component: SeeFDI },
			seeClose: { component: SeeCloseFDI },
			edit: { component: EditFDI },
			paid: { component: PaidFDI },
			download: { component: DownloadFDI },
			document: { component: DocumentFDI },
			arrow_up: { component: ArrowUpFDI },
			dashboard: { component: DashboardFDI },
			tiers: { component: TiersFDI },
			settings: { component: SettingsFDI },
			fournisseurs: { component: FournisseursFDI },
			clients: { component: ClientsFDI },
			contacts: { component: ContactsFDI },
			logout: { component: LogoutFDI },
			new_fournisseur_client: { component: NewFournisseurClientFDI },
			achats: { component: AchatsFDI },
			achats_children: { component: AchatsChildrenFDI },
			new_achats: { component: NewAchatsFDI },
			livraisons: { component: LivraisonsFDI },
			ventes: { component: VentesFDI },
			list_ventes: { component: ListVentesFDI },
			new_ventes: { component: NewVentesFDI },
			essences: { component: EssencesFDI },
			list_longueurs: { component: LongueursFDI },
			transports: { component: TransportsFDI },
		}
	},
    theme: {
        themes: {
            light: {
                primary: '#E65F5C',
                secondary: '#FFFFFF',
                accent: '#E08D79',
                error: '#7E0707',
                warning: '#ff9800',
                info: '#ffc107',
                success: '#69AE3F',
				background: '#FFFFFF',
				background_menu: '#F7F8F2',
				btn_nav: '#2D2D34',
				see: '#2D2D34',
				edit: '#2D2D34',
				delete: '#E65F5C',
				download: '#222222',
				document: '#222222',
				title: '#E08D79',
				subtitle: '#F3D491',
				btn_bg_add: '#E08D79',
				btn_txt_add: '#FFFFFF',
				blue: '#4365CD',
				gray: '#61616D',
            }
        }
    },
});
