







































import {Emit, Prop} from "vue-property-decorator";
import {InOut} from 'vue-inout-prop-decorator';
import {Component, Vue} from '@/shared/component';

import {Logement, Proprietaire} from '@/models';

import CrudProprietaireEdit from '@/components/Crud/Proprietaire/Edit.vue';
import storeProprietaire from "@/stores/modules/proprietaire";

@Component({
	components: {
		CrudProprietaireEdit,
	}
})
export default class CrudProprietaireEditDialog extends Vue {
	@InOut({ type: Proprietaire  , isVModel: true }) private value!: Proprietaire;

	@Emit()
	private onSubmit(proprietaire: Proprietaire) {
		this.onClose();
	}

	@Emit()
	private async onClose(): Promise<void> {
		if (this.value.id)
		{
			if (
				this.$route.name !== 'list_proprietaire'
			) {
				await this.$router.push({name: 'list_proprietaire'});
			} else {
				await storeProprietaire.dispatch('getC');
			}
		}
		this.value = null;
	}
}
