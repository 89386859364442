import {Tva} from '@/models';

export default [
	{
		path: '/tva',
		name: 'tva',
		meta: {
			title: 'Liste des longueurs d\'achat ',
			granted: [ 'API_GET_TVA' ]
		},
		component: () => import(/* webpackChunkName: "dashboard" */ '../views/Tva.vue')
	},
];