











































import {Prop, Watch} from "vue-property-decorator";
import {Component, Vue} from '@/shared/component';
import {Stored} from 'vue-stored-prop-decorator';

import {LogementFilter, storeLogementAvailable} from '@/stores/modules/logement';

import CrudList from '@/components/Crud/List.vue';
import TableHead from '@/components/Paginate/TableHead.vue';
import TableCell from '@/components/Paginate/TableCell.vue';
import {Locataire, Logement, Proprietaire} from "@/models";
import CrudLogementEditDialog from "@/components/Crud/Logement/EditDialog.vue";
import CrudDeleteDialog from "@/components/Crud/DeleteDialog.vue";
import {InOut} from "vue-inout-prop-decorator/index";

@Component({
	components: {
		CrudList,
		TableHead,
		TableCell,
		CrudLogementEditDialog,
		CrudDeleteDialog,
	}
})
export default class CrudLogementList extends Vue {

	@Prop({type: Boolean, default: true  }) public paginate!: boolean;
	@Prop({type: Boolean, default: false }) public dense!   : boolean;
	@InOut({ type: Locataire  , isVModel: true }) private value!: Locataire;
	@Stored(() => storeLogementAvailable) private filters!: LogementFilter;

	private storeLogementAvailable = storeLogementAvailable;

	private singleSelect = true;

	private selected = [];

	private headers = [
		{
			text: 'Propriétaire',
			value: 'proprietaire.fullName',
		},
		{ text: 'Désignation', value: 'name', groupable: false  },
		{ text: 'Adresse', value: 'fullAddress', groupable: false  },
	];

	public mounted(): void {
		this.filters.availability = true;
		storeLogementAvailable.dispatch('getC', { filters: true });
	}

	@Watch('selected')
	private onChange(): void {
		if (this.selected.length > 0) {
			this.value.logement = this.selected[0]
		} else {
			this.value.logement = null
		}
	}

}
