import Vue from 'vue';
import Vuex from 'vuex';
import {ResultType, XHTTPService} from '@/shared/xhttp';
import {
	PaginatorActionWithFilters,
	PaginatorFilterState,
	PaginatorMutation,
	StandardAction
} from '@/shared/utils';
import {Tva} from '@/models';
import {TvaService} from '@/xhttp';
import store from '@/stores';

Vue.use(Vuex);

export class TvaFilter {
	
	public name: string = '';
	
	public toString(): string {
		return JSON.stringify(this);
	}
}

class TvaState extends PaginatorFilterState<TvaFilter> {
	public tva: Tva = null;
	public tvas: ResultType<Tva> = null;
	public tvasAll: ResultType<Tva> = null;
	public constructor() {
		super(new TvaFilter(), 'tvas', 'tva');
	}
}

class TvaStore {
	
	@XHTTPService(() => Tva)
	private tvaService: TvaService;
	
	public state: TvaState = new TvaState();
	
	public mutations = {
		...PaginatorMutation(() => storeTva, 'tvas', 'tva'),
	};
	
	public actions = {
		...StandardAction<Tva, TvaState>(() => Tva, 'tva'),
		...PaginatorActionWithFilters<Tva, TvaState>(() => Tva, 'tvas', 'tva'),
	};
	
}
const instance = new TvaStore();
const storeTva = new Vuex.Store(instance);
store.registerModule('tva', storeTva);
export default storeTva;
