import {AscDesc, GenericService, Result, ResultType} from '@/shared/xhttp';
import {AvisLoyer} from "@/models/AvisLoyer";
import caller from "@/shared/caller";
import {DateHelper} from "@/shared/utils";

export class AvisLoyerService extends GenericService<AvisLoyer> {

	public async putChangeState(avisLoyer: AvisLoyer): Promise<void> {
		await this.put(`/${avisLoyer.id}/change-state`);
	}

	public async createAllAvisLoyer(date: Date): Promise<void> {
		await this.put(`/create-all-avis-loyer/${date}`);
	}

}
