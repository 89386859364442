import Vue from 'vue';
import Vuex from 'vuex';
import {AscDesc, ResultType, XHTTPService} from '@/shared/xhttp';
import {NumberFilter} from '@/shared/filter';
import {
	PaginatorActionWithFilters,
	PaginatorFilterState,
	PaginatorMutationFilter,
	StandardAction
} from '@/shared/utils';
import {Proprietaire} from '@/models';
import {ProprietaireService} from '@/xhttp';
import store from '@/stores';

Vue.use(Vuex);

export class ProprietaireFilter {
	
	firstName: string = '';
	lastName: string = '';
	
	public clear(): void {
		this.firstName = '';
		this.lastName = '';
	}

	public toString(): string {
		return JSON.stringify({
			firstName: this.firstName,
			lastName : this.lastName,
		});
	}
}

class ProprietaireState extends PaginatorFilterState<ProprietaireFilter> {
	public proprietaire: Proprietaire = null;
	public proprietaires: ResultType<Proprietaire> = null;
	public constructor() {
		super(new ProprietaireFilter(), 'proprietaires', 'proprietaire', 'id', AscDesc.DESC);
	}
}

class ProprietaireStore {
	
	@XHTTPService(() => Proprietaire)
	private proprietaireService: ProprietaireService;
	
	public state: ProprietaireState = new ProprietaireState();
	
	public mutations = {
		...PaginatorMutationFilter(() => storeProprietaire, 'proprietaires', 'proprietaire'),
	};
	
	public actions = {
		...PaginatorActionWithFilters<Proprietaire, ProprietaireState>(() => Proprietaire, 'proprietaires', 'proprietaire'),
		...StandardAction<Proprietaire, ProprietaireState>(() => Proprietaire, 'proprietaire'),
	};
	
}
const instance = new ProprietaireStore();
const storeProprietaire = new Vuex.Store(instance);
store.registerModule('proprietaire', storeProprietaire);
export default storeProprietaire;
