import Vue from 'vue';
import Vuex, {ActionContext, Store} from 'vuex';
import {AscDesc, ResultType, XHTTPService} from '@/shared/xhttp';
import {NumberFilter} from '@/shared/filter';
import {DateFilter} from '@/shared/filter';
import {
	PaginatorActionWithFilters,
	PaginatorFilterState,
	PaginatorMutationFilter,
	StandardAction
} from '@/shared/utils';
import {AvisLoyer} from "@/models/AvisLoyer";
import {AvisLoyerService} from "@/xhttp";
import store from '@/stores';
import {Locataire, Proprietaire} from "@/models";


Vue.use(Vuex);

export class AvisLoyerFilter {

	public name: string = '';
	public address: string = '';
	public zipCode: string = '';
	public town: string = '';
	public state: boolean[] = [];
	proprietaire: Proprietaire[] = [];

	public clear(): void {
		this.proprietaire = [];
	}

	public toString(): string {
		return JSON.stringify({
			name  : this.name,
			address  : this.address,
			zipCode  : this.zipCode,
			town  : this.town,
			state  : this.state,
			proprietaire    : this.proprietaire.map(t => t.id),
		});
	}

}

class AvisLoyerState extends PaginatorFilterState<AvisLoyerFilter> {
	public avisLoyer: AvisLoyer = null;
	public avisLoyers: ResultType<AvisLoyer> = null;
	public constructor() {
		super(new AvisLoyerFilter(), 'avisLoyers', 'avisLoyer', 'id', AscDesc.DESC, 100);
	}
}

class AvisLoyerStore {

	@XHTTPService(() => AvisLoyer)
	private avisLoyerService: AvisLoyerService;
	private  _store: Store<any>;

	public state: AvisLoyerState = new AvisLoyerState();

	public mutations = {
		...PaginatorMutationFilter(() => this._store, 'avisLoyers', 'avisLoyer'),
	};

	public actions = {
		...PaginatorActionWithFilters<AvisLoyer, AvisLoyerState>(() => AvisLoyer, 'avisLoyers', 'avisLoyer'),
		...StandardAction<AvisLoyer, AvisLoyerState>(() => AvisLoyer, 'avisLoyer'),

		putChangeState(context: ActionContext<AvisLoyerState, any>, avisLoyer: AvisLoyer) {
			return instance.avisLoyerService.putChangeState(avisLoyer);
		},

		createAllAvisLoyer(context: ActionContext<AvisLoyerState, any>, date: Date) {
			return instance.avisLoyerService.createAllAvisLoyer(date);
		},
	};


	public init(store: Store<any>): this {
		this._store = store;
		return this;
	}
}

const instance = new AvisLoyerStore();
const storeAvisLoyer = new Vuex.Store(instance);
instance.init(storeAvisLoyer);

const instanceAvailable = new AvisLoyerStore();
export const storeAvisLoyerArchive = new Vuex.Store(instanceAvailable);
instanceAvailable.init(storeAvisLoyerArchive);

store.registerModule('avisLoyer', storeAvisLoyer);
store.registerModule('avisLoyerArchive', storeAvisLoyerArchive);
export default storeAvisLoyer;