import {Media} from "./Media";
import {DocumentProprietaireService} from "@/xhttp/DocumentProprietaireService";
import {XHTTP} from "@/shared/xhttp";
import {Proprietaire} from "@/models/Proprietaire";

@XHTTP(DocumentProprietaireService, '/document/proprietaire')
export class DocumentProprietaire {

	private _id: number;
	private _media: Media;
	private _proprietaire: Proprietaire;
	private _createdAt: Date;

	/////////////
	// Getters //
	/////////////

	public get id(): number {
		return this._id;
	}

	public get media(): Media {
		return this._media;
	}

	public get proprietaire(): Proprietaire {
		return this._proprietaire;
	}

	public get createdAt(): Date {
		return this._createdAt;
	}

	/////////////
	// Setters //
	/////////////

	public set media(value: Media) {
		this._media = value;
	}

	public set proprietaire(value: Proprietaire) {
		this._proprietaire = value;
	}

	///////////////
	// Serialize //
	///////////////

	public fromJSON(data: any): this {
		this._id        = data.id;
		this._media     = (new Media()).fromJSON(data.media);
		this._createdAt = data.createdAt ? new Date(data.createdAt) : null;
		return this;
	}
}