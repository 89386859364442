import {AvisLoyer} from "@/models/AvisLoyer";

export default [
	{
		path: '/avis-loyer',
		name: 'list_avis_loyers',
		meta: {
			title: 'AvisLoyer en cours',
			granted: [ 'API_GET_AVIS_LOYER' ]
		},
		component: () => import(/* webpackChunkName: "dashboard" */ '../views/AvisLoyer.vue')
	},
	{
		path: '/avis-loyer/show/:id',
		name: 'avis-loyer-show',
		props: {
			slotData: () => ({
				component: import(/* webpackChunkName: "dashboard" */ '../components/Crud/AvisLoyer/Show.vue'),
				class: AvisLoyer
			}),
			editRoute: { name: 'avis-loyer-edit' },
		},
		meta: {
			title: 'Détails d\'un avisLoyer',
			granted: [ 'API_GET_AVIS_LOYER' ]
		},
		component: () => import(/* webpackChunkName: "dashboard" */ '../views/EditShow.vue')
	},
	{
		path: '/avis-loyer/edit/:id',
		name: 'avis-loyer-edit',
		props: {
			slotData: () => ({
				component: import(/* webpackChunkName: "dashboard" */ '../components/Crud/AvisLoyer/Edit.vue'),
				class: AvisLoyer
			}),
		},
		meta: {
			title: 'Edition d\'une livraison',
			granted: [ 'API_PUT_AVIS_LOYER' ]
		},
		component: () => import(/* webpackChunkName: "dashboard" */ '../views/EditShow.vue')
	},
];
