








































	import {Emit} from "vue-property-decorator";
	import {InOut} from 'vue-inout-prop-decorator';
	import {Component, Vue} from '@/shared/component';

	import {AvisLoyer, Locataire} from '@/models';

	import CrudAvisLoyerEditAll from '@/components/Crud/AvisLoyer/EditAll.vue';
	import storeAvisLoyer from "@/stores/modules/avisLoyer";
	import storeLocataire from "@/stores/modules/locataire";

	import axios from 'axios';

	@Component({
		components: {
			CrudAvisLoyerEditAll,
		}
	})
	export default class CrudAvisLoyerEditDialog extends Vue {
		@InOut({ type: AvisLoyer  , isVModel: true }) private value!: AvisLoyer;

		@Emit()
		private onSubmit() {
			storeAvisLoyer.dispatch('getC');
			this.onClose();
		}

		@Emit()
		private async onClose(): Promise<void> {
			this.value = null;
		}

}
