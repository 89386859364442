import {XHTTP} from '@/shared/xhttp';
import {TvaService} from '@/xhttp';

@XHTTP(TvaService, '/tva')
export class Tva {
	
	private _id: number;
	private _number: number;
	private _use: boolean;
	
	/////////////
	// Getters //
	/////////////
	
	public get id(): number {
		return this._id;
	}

	public get number(): number {
		return this._number;
	}

	public get use(): boolean {
		return this._use;
	}
	
	/////////////
	// Setters //
	/////////////

	public set id(value: number) {
		this._id = value;
	}

	public set number(value: number) {
		this._number = value;
	}

	///////////////
	// Serialize //
	///////////////

	public toJSON(): any {
		return {
			id     : this._id,
			number: this._number,
		};
	}
	
	public fromJSON(data: any): this {
		this._id   = data.id;
		this._number   = data.number;
		this._use  = data.use;
		return this;
	}

	////////////
	// Others //
	////////////
	
}