




































































































import {Component} from '@/shared/component';
import {AbstractEditVue} from '@/components/Abstract/AbstractEditVue';
import {AvisLoyer, Locataire, User} from '@/models';
import {PeriodicityType} from '@/models';
import storeAvisLoyer from '@/stores/modules/avisLoyer';
import storeLogement from '@/stores/modules/logement';

import InputPhone from '@/components/Input/Phone.vue';
import InputZipCode from "@/components/Input/ZipCode.vue";
import InputDatePicker from "@/components/Input/DatePicker.vue";
import CrudLogementListSelect from "@/components/Crud/Logement/ListSelect.vue";
import {Emit} from "vue-property-decorator";
import store from "@/stores";
import storeTva from "@/stores/modules/tva";
import storeLocataire from "@/stores/modules/locataire";
import {InOut} from "vue-inout-prop-decorator/index";
import axios from "axios";
import {Stored} from "vue-stored-prop-decorator";
import storeUser from "@/stores/modules/user";

@Component({
	components: {
		InputPhone,
		InputZipCode,
		InputDatePicker,
		CrudLogementListSelect,
	}
})
export default class CrudAvisLoyerEdit extends AbstractEditVue<AvisLoyer> {

	private showResult: number = 0;
	private array: any = [];

	@Stored(() => storeUser) me!: User;

	public constructor() {
		super(
			storeAvisLoyer,
			'AvisLoyer modifié',
			'AvisLoyer ajouté',
		);
	}

	public async mounted(): Promise<void> {
		await super.mounted();
		await storeAvisLoyer.dispatch('getC');
	}

	protected onNext(): void {
		axios.put(process.env.VUE_APP_BACK_BASE_URL + "/api/avis-loyer/create-all-avis-loyer/" + this.value.date, {}, {
			headers: {
				'Authorization': 'BEARER ' + this.me.currentToken.id
			}
		}).then(response => {

			this.array = response.data;
			this.showResult = 1;
		});
	}

	protected async onSubmit(): Promise<void> {
		this.$emit('on-submit', this.value);
	}

}
