












































































































	import {Component, Vue} from '@/shared/component';
	import {Stored} from "vue-stored-prop-decorator/index";
	import {AvisLoyer, Locataire, Logement, Proprietaire, User} from '@/models';
	import {Emit} from 'vue-property-decorator';
	
	import storeUser from '@/stores/modules/user';
	import CrudProprietaireEditDialog from "@/components/Crud/Proprietaire/EditDialog.vue";
	import CrudLogementEditDialog from "@/components/Crud/Logement/EditDialog.vue";
	import CrudLocataireEditDialog from "@/components/Crud/Locataire/EditDialog.vue";
	import CrudAvisLoyerEditAllDialog from "@/components/Crud/AvisLoyer/EditAllDialog.vue";

	import { version } from '../../../package.json';

	@Component({
		components: {
			CrudProprietaireEditDialog,
			CrudLogementEditDialog,
			CrudLocataireEditDialog,
			CrudAvisLoyerEditAllDialog,
		}
	})

	export default class NavigationMainMenu extends Vue {

		@Stored(() => storeUser) me!: User;

		private reduce: boolean = false;
		private open: boolean = false;
		private appVersion = version;

		private items = [
			{
				title: 'Les propriétaires',
				icon: 'mdi-account',
				grant: 'NAV_PROPRIETAIRE',
				children: [
					{
						route: { name: 'list_proprietaire' },
						title: 'Liste des propriétaires',
						icon: 'mdi-file-document-outline',
						grant: 'API_GET_PROPRIETAIRE',
					},
					{
						title: 'Ajouter un propriétaire',
						icon: 'mdi-account-plus',
						grant: 'API_POST_PROPRIETAIRE',
						clickparameter: 'Proprietaire',
					},
				]
			},
			{
				title: 'Les logements',
				icon: 'mdi-home',
				grant: 'NAV_LOGEMENT',
				children: [
				{
					route: { name: 'list_logements' },
					title: 'Liste des logements',
					icon: 'mdi-file-document-outline',
					grant: 'API_GET_LOGEMENT',
				},
				{
					title: 'Ajouter un logement',
					icon: 'mdi-home-plus',
					grant: 'API_POST_LOGEMENT',
					clickparameter: 'Logement',
				},
				]
			},
			{
				title: 'Les locataires',
				icon: 'mdi-folder-account',
				grant: 'NAV_LOCATAIRE',
				children: [
					{
						route: { name: 'list_locataires' },
						title: 'Locataires en cours',
						icon: 'mdi-file-document-outline',
						grant: 'API_GET_LOCATAIRE',
					},
					{
						route: { name: 'list_locataires_archive' },
						title: 'Archive des locataires',
						icon: 'mdi-package-variant-closed',
						grant: 'API_GET_LOCATAIRE',
					},
					{
						title: 'Ajouter un locataire',
						icon: 'mdi-folder-plus',
						grant: 'API_POST_LOCATAIRE',
						clickparameter: 'Locataire',
					},
				]
			},
			{
				title: 'Suivi des loyers',
				icon: 'mdi-clipboard-text',
				grant: 'NAV_AVIS_LOYER',
				children: [
					{
						route: { name: 'list_avis_loyers' },
						title: 'Liste des avis de loyers',
						icon: 'mdi-file-document-outline',
						grant: 'API_GET_AVIS_LOYER',
					},
					{
						title: 'Générer les avis de loyer',
						icon: 'mdi-file-plus',
						grant: 'API_POST_AVIS_LOYER',
						clickparameter: 'AvisLoyer',
					},
				]
			},
			{
				title: 'Paramétrage',
				icon: 'mdi-cog',
				grant: 'NAV_PARAMETRES',
				children: [
					{
						route: { name: 'tva' },
						title: 'Liste des TVA',
						icon: 'mdi-currency-eur',
						grant: 'API_GET_TVA',
					},
					{
						route: { name: 'list_user' },
						title: 'Liste des utilisateurs',
						icon: 'mdi-account',
						grant: 'API_GET_USER',
					},
					/*{
						route: { name: 'token' },
						title: 'API Tokens',
						icon: 'mdi-key-variant',
					},*/
				]
			},
		];

		private onOpen() {
			this.open = true;
			this.reduce = false;
		}

		private onReduce() {
			if (this.open) {
				this.open = false;
			} else {
				this.reduce = true;
			}
		}

		private editProprietaire: Proprietaire = null;
		private editLogement: Logement = null;
		private editLocataire: Locataire = null;
		private editAvisLoyer: AvisLoyer = null;

		@Emit()
		private onCreate($parameter): void {
			switch ($parameter) {
  				case 'Proprietaire':
					this.editProprietaire = new Proprietaire();
    			break;
  				case 'Logement':
					this.editLogement = new Logement();
    			break;
				case 'Locataire':
					this.editLocataire = new Locataire();
				break;
				case 'AvisLoyer':
					this.editAvisLoyer = new AvisLoyer();
				break;
			}
		}

	}
