import caller from '@/shared/caller';
import {Result, ResultType} from '../Result';
import {IService} from './IService';

export type AscDesc = 'asc'|'desc';
export const AscDesc = {
	ASC: 'asc' as AscDesc,
	DESC: 'desc' as AscDesc
};

export class GenericService<T> implements IService<T> {
	
	private _entityClass: any;
	private _baseUrl: string;
	
	public constructor (entityClass: any, url: any) {
		this._entityClass  = entityClass;
		this._baseUrl      = process.env.VUE_APP_BACK_BASE_URL+'/api'+url; // todo generate env file
	}
	
	public get entityClass(): any {
		return this._entityClass;
	}
	
	public get baseUrl(): string {
		return this._baseUrl;
	}
	
	public getC(url: string = '', limit: number = 20, page: number = 0, order: string = null, direction: AscDesc = null, query: any = {}): Promise<ResultType<T>> {
		query['page'] = page;
		query['limit'] = limit;
		if (order    ) query['order'    ] = order;
		if (direction) query['direction'] = direction;
		return caller.get(this._baseUrl + url, Result<T>(this._entityClass), query)
	}
	
	public get(url: string = '', query: any = {}): Promise<T> {
		return caller.get(this._baseUrl + url, this._entityClass, query)
	}
	
	public post(url: string = '', data: any = {} , query: any = {}): Promise<T> {
		return caller.post(this._baseUrl + url, this._entityClass, data, query)
	}
	
	public put(url: string = '', data: any = {} , query: any = {}): Promise<T> {
		return caller.put(this._baseUrl + url, this._entityClass, data, query)
	}
	
	public patch(url: string = '', data: any = {} , query: any = {}): Promise<T> {
		return caller.patch(this._baseUrl + url, this._entityClass, data, query)
	}
	
	public delete(url: string = '', data: any = {} , query: any = {}): Promise<T> {
		return caller.delete(this._baseUrl + url, this._entityClass, data, query)
	}

	public getAll(): Promise<ResultType<T>> {
		return this.getC('', 200);
	}
}
