
































































import {Component} from '@/shared/component';
import {AbstractEditVue} from '@/components/Abstract/AbstractEditVue';
import {Logement} from '@/models';

import storeLogement from '@/stores/modules/logement';
import storeProprietaire from '@/stores/modules/proprietaire';

import InputPhone from '@/components/Input/Phone.vue';
import InputZipCode from "@/components/Input/ZipCode.vue";

@Component({
	components: {
		InputPhone,
		InputZipCode,
	}
})
export default class CrudLogementEdit extends AbstractEditVue<Logement> {

	private storeProprietaire = storeProprietaire;

	public constructor() {
		super(
			storeLogement,
			'Logement modifié',
			'Logement ajouté',
		);
	}

	public async mounted(): Promise<void> {
		await super.mounted();
		await storeProprietaire.dispatch('getC');
	}

}
