







































	import {Emit} from "vue-property-decorator";
	import {InOut} from 'vue-inout-prop-decorator';
	import {Component, Vue} from '@/shared/component';

	import {Locataire} from '@/models';

	import CrudLocataireEdit from '@/components/Crud/Locataire/Edit.vue';
	import storeLocataire, {LocataireFilter} from "@/stores/modules/locataire";
	import {Stored} from "vue-stored-prop-decorator/index";

	@Component({
		components: {
			CrudLocataireEdit,
		}
	})
	export default class CrudLocataireEditDialog extends Vue {
		@InOut({ type: Locataire  , isVModel: true }) private value!: Locataire;
		@Stored(() => storeLocataire) private filters!: LocataireFilter;

		@Emit()
		private onSubmit(locataire: Locataire) {
			this.onClose();
		}

		@Emit()
		private async onClose(): Promise<void> {
			if (this.value.id)
			{
				if (
					this.$route.name !== 'list_locataires'
				) {
					await this.$router.push({name: 'list_locataires'});
				} else {
					this.filters.state = [true];
					await storeLocataire.dispatch('getC', {filters: true});
				}
			}
			this.value = null;
		}

}
