import {Proprietaire} from "@/models";

export default [
	{
		path: '/proprietaire',
		name: 'list_proprietaire',
		meta: {
			title: 'Liste des proprietaires',
			granted: [ 'API_GET_PROPRIETAIRE' ]
		},
		component: () => import(/* webpackChunkName: "dashboard" */ '../views/Proprietaire.vue')
	},
	{
		path: '/proprietaire/show/:id',
		name: 'proprietaire-show',
		props: {
			slotData: () => ({
				component: import(/* webpackChunkName: "dashboard" */ '../components/Crud/Proprietaire/Show.vue'),
				class: Proprietaire
			}),
			editRoute: { name: 'proprietaire-edit' },
		},
		meta: {
			title: 'Détails d\'un proprietaire',
			granted: [ 'API_GET_PROPRIETAIRE' ]
		},
		component: () => import(/* webpackChunkName: "dashboard" */ '../views/EditShow.vue')
	},
	{
		path: '/proprietaire/edit/:id',
		name: 'proprietaire-edit',
		props: {
			slotData: () => ({
				component: import(/* webpackChunkName: "dashboard" */ '../components/Crud/Proprietaire/Edit.vue'),
				class: Proprietaire
			}),
		},
		meta: {
			title: 'Edition d\'un proprietaire',
			granted: [ 'API_PUT_PROPRIETAIRE' ]
		},
		component: () => import(/* webpackChunkName: "dashboard" */ '../views/EditShow.vue')
	},
];
