






























































































import {Component} from '@/shared/component';
import {AbstractEditVue} from '@/components/Abstract/AbstractEditVue';
import {Proprietaire} from '@/models';

import storeProprietaire from '@/stores/modules/proprietaire';

import InputPhone from '@/components/Input/Phone.vue';
import InputZipCode from "@/components/Input/ZipCode.vue";

@Component({
	components: {
		InputPhone,
		InputZipCode,
	}
})
export default class CrudProprietaireEdit extends AbstractEditVue<Proprietaire> {
	public constructor() {
		super(
			storeProprietaire,
			'Propriétaire modifié',
			'Propriétaire ajouté',
		);
	}

	public async mounted(): Promise<void>	{
		await super.mounted();
		this.checkInputs();
	}

	private disableBtn = true;

	public checkInputs() {
		if (this.item)
		{
			if (this.item.lastName || this.item.firstName || this.item.society) {
				this.disableBtn = false;
			} else {
				this.disableBtn = true;
			}
		}
	}
}
