import {AscDesc, GenericService, Result, ResultType} from '@/shared/xhttp';
import {Locataire} from "@/models/Locataire";
import caller from "@/shared/caller";
import {DateHelper} from "@/shared/utils";

export class LocataireService extends GenericService<Locataire> {

	public async putMarkState(locataire: Locataire): Promise<void> {
		await this.put(`/mark-state/${locataire.id}`);
	}

	public async createAvisLoyer(array: Array<any>): Promise<void> {
		await this.put(`/create-avis-loyer/${array[0]}/${array[1]}`);
	}
}
